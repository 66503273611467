<template>
  <div>
    <div class="wrap">
      <div class="top-area pt-[80px]">
        <router-view name="title"/>
        <!--                <div class="title">-->
        <!--                    NFT-->
        <!--                </div>-->
        <div class="sub-menu">
          <!-- 페이지 이동 처리 -->
          <div class="sub-menu-item" :class="nav.active ? 'active' : ''" v-for="nav in navs" :key="nav.path">
            <router-link :to="{path: nav.path}" v-if="!nav.isLoading">{{ nav.name }}</router-link>
            <p class="m-0 " v-else @click="isShowLoading=true">{{nav.name}}</p>
          </div>
<!--          <div class="sub-menu-item" :class="$route.name == 'NftList' ? 'active' : ''">
            <router-link :to="{path: `/users/mypage`}">Wine NFT</router-link>
          </div>
          <div class="sub-menu-item" :class="$route.name == 'MembershipNftList' ? 'active' : ''">
            <router-link :to="{path: `/users/mypage/membership`}">Membership NFT</router-link>
          </div>
          &lt;!&ndash;<div class="sub-menu-item">Wish List</div>&ndash;&gt;
          &lt;!&ndash; TODO:: 2022.07.10 배포 버전에서는 제외&ndash;&gt;

          <div class="sub-menu-item" :class="$route.name == 'MembershipNftList' ? 'active' : ''">
            <router-link :to="{path: `/users/mypage/activity`}">Activity</router-link>
          </div>
          <div class="sub-menu-item" :class="$route.name == 'Profile' ? 'active' : ''">
            <router-link :to="{path: `/users/mypage/profile`}">Profile</router-link>
          </div>-->
        </div>
      </div>
      <router-view></router-view>
    </div>
    <AlertModal
        title="알림"
        content="현재 준비중입니다."
        v-if="isShowLoading"
        :isOpen="isShowLoading"
        :confirmClickBtn="() => {isShowLoading=false}"
        :button-class="'primary-btn'"

    />
  </div>
</template>

<script>
import _nav from "@/views/users/mypage/_nav";
import AlertModal from "@/components/modals/AlertModal";
export default {
  components: {AlertModal},
  data(){
    return{
      navs: [],
      isShowLoading:false
    }
  },
  methods:{
    /*
    clickNav(nav){
      if(nav.name == 'Activity'){
        alert();
      }
    }
    */
  },
  created() {
    _nav.forEach((nav) => {
      nav.active = false;

      if(nav.path === this.$route.path){
        nav.active = true;
      }

      if(nav.children){
        nav.children.forEach((child) => {
          if(child.path === this.$route.path){
            nav.active = true;
            child.active = true;
          }
        })
      }

      this.navs.push(nav)
    })
  }

}
</script>

<style scoped lang="scss">
@import "@/assets/scss/mypage.scss";
</style>
